@font-face {
  font-family: "Verb";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular.woff2")
      format("woff2"),
    url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular.woff")
      format("woff");
}
@font-face {
  font-family: "Verb";
  font-style: italic;
  font-weight: normal;
  font-display: auto;
  src: url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-Italic.woff2")
      format("woff2"),
    url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-Italic.woff")
      format("woff");
}
@font-face {
  font-family: "Verb";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbLight.woff2")
      format("woff2"),
    url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbLight.woff")
      format("woff");
}
@font-face {
  font-family: "Verb";
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-Bold.woff2")
      format("woff2"),
    url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-Bold.woff")
      format("woff");
}
@font-face {
  font-family: "Verb";
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-BoldItalic.woff2")
      format("woff2"),
    url("https://www.measurabl.com/wp-content/uploads/2019/07/VerbRegular-BoldItalic.woff")
      format("woff");
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verb, Roboto, sans-serif;
}
body {
  color: #2b3034;
}

a {
  text-decoration: none;
}
a:link {
  color: #2db892;
  text-decoration: none;
}
a:visited {
  color: #2db892;
  text-decoration: none;
}
a:hover {
  color: #46d2ac;
  text-decoration: none;
}
a:active {
  color: #435571;
  text-decoration: none;
}
a:focus {
  color: #2d8eb8;
  text-decoration: none;
}
